<template>
  <div class="c-user-card mb-4">
    <div v-if="isLoadingUserInfo" class="text-center my-2">
      <b-spinner
        variant="primary"
        style="width: 2rem; height: 2rem"
      ></b-spinner>
    </div>
    <div v-else class="row justify-content-between flex-direction-reverse">
      <div class="col-12 col-lg-9">
        <h4>
          {{ user.name }} {{ user.surname }} -
          <small class="mr-3"> {{ $t(`genderExcell.${user.gender}`) }}</small>

          <span class="d-block d-lg-inline">
            <span
              :class="
                user.isActive
                  ? 'badge badge-soft-success'
                  : 'badge badge-soft-danger'
              "
              >{{ user.isActive ? $t("activeUser") : $t("passiveUser") }}</span
            >
            <small
              :class="`badge badge-soft-${userStatus(user.status).class} ml-2`"
              >{{ $t(userStatus(user.status).text) }}</small
            >
          </span>
        </h4>

        <p>
          <span>
            <a :href="`mailto:${user.email}`"
              ><font-awesome-icon :icon="faEnvelope" /> {{ user.email }}</a
            >
            -
            <font-awesome-icon :icon="faPhone" /> {{ user.phoneNo }}
            -
            <span class="d-inline-block">
              <font-awesome-icon :icon="faBirthdayCake" class="text-danger" />
              {{ setBirthday(user.birthDate) }}
            </span>
            -
            <span>
              {{ $t(`genderLong.${user.gender}`) }}
            </span>
          </span>
        </p>
        <div class="my-2">
          <export-excel
            :name="`${user.name}-${user.surname}-${user.taxYear}.${getOS.excell}`"
            :fields="json_fields"
            :type="getOS.excell"
            :data="[user]"
          >
            <button class="blue-button bg-success btn-xs font-size-15">
              <font-awesome-icon :icon="faFileExcel"></font-awesome-icon>
              {{ $t("exportToExcel") }}
            </button>
          </export-excel>
        </div>
        <div v-if="showDetail">
          <img
            v-if="user.paymentServiceId"
            width="45"
            @mouseover="showPayInfoIndex = user.id"
            @mouseleave="showPayInfoIndex = null"
            :src="
              require(`@/assets/img/paymentOptions/${user.paymentServiceId}.png`)
            "
            alt=""
          />
          <div class="document-message" v-if="showPayInfoIndex === user.id">
            {{ $t("payerFullname") }}: {{ user.payerFullname }} <br />
            E-mail: {{ user.payerEmail }} <br />
            {{ $t("amount") }}: {{ user.amount }} {{ user.amountCurrency }}
          </div>
          <div v-if="user.payerFullname">
            {{ $t("payerFullname") }}: {{ user.payerFullname }} <br />
            E-mail: {{ user.payerEmail }} <br />
            {{ $t("amount") }}: {{ user.amount }} {{ user.amountCurrency }}
            <br />
            <span v-if="user.payerFullname">
              {{ $t("createdDate") }}:
              {{
                new Date(user.createdDate).toLocaleString(
                  this.$i18n.locale )
              }}</span
            >
          </div>
          <h5 class="mt-4 text-primary">{{ $t("accountInfo") }}</h5>
          <div class="mt-2">
            <font-awesome-icon :icon="faClock" /> {{ $t("createdAt") }}:
            {{ setDate(user.createdDate) }}
          </div>
          <div class="mt-2">
            <font-awesome-icon :icon="faCreditCard" /> IBAN:
            <strong> {{ user.iban }}</strong>
          </div>
          <div class="mt-2">
            <font-awesome-icon :icon="faBarsStaggered" />
            {{ $t("taxIdentifyNo") }}:
            <strong> {{ user.taxIdentifyNo }}</strong>
          </div>
          <div class="mt-2">
            <font-awesome-icon :icon="faCalendarDays" /> {{ $t("taxYear") }}:
            <strong> {{ user.taxYear }}</strong>
          </div>
          <div class="mt-2">
            <font-awesome-icon :icon="faRing" />
            {{ $t("detailsMaritalStatus") }}:
            <strong>
              {{ $t(setMarriageStatus(user.marriageStatus).text) }}
              {{
                user.userPartner
                  ? ` ~ ${$t("date")}: ` +
                    new Date(user.userPartner.marriageDate).toLocaleDateString(
                      this.$i18n.locale,
                      {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }
                    )
                  : ""
              }}</strong
            >
          </div>
          <div class="mt-4">
            <h6>
              <font-awesome-icon :icon="faAddressBook" />
              {{ $t("addressInfo") }}
            </h6>
            <div class="mt-2">
              {{ $t("address") }}: {{ user.address.street }},
              {{ $t("number") }} {{ user.address.apartmentNo }},
              {{ user.address.city }},
              {{ user.address.postalCode }}
            </div>
          </div>
        </div>
      </div>
      <div class="account-settings col-12 col-lg-3 mt-3 mt-md-0">
        <b-form-select
          v-model="status"
          @change="() => UPDATE_STATUS(user.id, user.taxYear)"
          size="lg"
          class="lang-select-box"
        >
          <b-form-select-option
            :disabled="STATUS_CHANGE_DISABLED(stat)"
            :value="stat.value"
            v-for="(stat, i) in statusList"
            :key="i"
            >{{ $t(stat.text) }}</b-form-select-option
          >
        </b-form-select>
        <button
          class="blue-button w-100 mt-2 mb-2"
          @click="showDetail = !showDetail"
        >
          {{ $t("seeDetail") }} <font-awesome-icon :icon="faChevronDown" />
        </button>
        <router-link :to="`/admin/kunden/${$route.params.id}`">
          <button class="blue-button bg-info w-100">
            {{ $t("viewAllTaxReturn") }}
          </button>
        </router-link>
      </div>
      <div class="col-12 mt-4" v-if="user.status === 6">
        <div class="alert alert-info">
          Müşteriniz için ödeme linki oluşturuldu.
          <strong>
            Müşteri ödeme linkini açabilmesi için hesabına giriş yapması
            gerekmektedir.</strong
          >
          Aşağıdaki linkten {{ $route.params.year }} yılının vergi
          beyannamesinin ödemesini alabilirsiniz.
          <div class="mt-4 text-right">
            <a
              :href="`https://flinktax.com/#/pay/${user.id}/${$route.params.year}`"
              >{{
                `https://flinktax.com/#/pay/${user.id}/${$route.params.year}`
              }}</a
            >
          </div>
          <div class="mt-1 text-right">
            <button class="blue-button bg-info m-2">
              Ödeme linki için tıklayın
            </button>
            <button
              @click="copyClickboard()"
              class="blue-button m-2"
              :class="{ 'bg-secondary': !copied, 'bg-success': copied }"
            >
              <span v-if="copied">
                <FontAwesomeIcon :icon="faClipboard" /> {{ $t("copied") }}
              </span>
              <span v-else>
                <FontAwesomeIcon :icon="faClipboard" /> Ödeme linkini kopyala
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, putUserStatus } from "../../../services/modules/User";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUser,
  faBirthdayCake,
  faEnvelope,
  faClock,
  faCreditCard,
  faBarsStaggered,
  faCalendarDays,
  faRing,
  faAddressBook,
  faPhone,
  faFileExcel,
  faChevronDown,
  faClipboard,
  faChildren,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faUser,
      faClipboard,
      faFileExcel,
      faBirthdayCake,
      faEnvelope,
      faPhone,
      faAddressBook,
      faCreditCard,
      faCalendarDays,
      faBarsStaggered,
      faChevronDown,
      faChildren,
      faRing,
      faClock,
      user: {},
      showDetail: false,
      isLoadingUserInfo: true,
      status: -1,
      showPayInfoIndex: -1,
      json_fields: {
        "Kunden ID": "invalid",
        Kundennummer: "invalid",
        Abteilung: "invalid",
        Anrede: {
          field: "gender",
          callback: (value) => {
            return this.$t(`genderExcell.${value}`);
          },
        },
        "Persönlich/Vertraulich": "invalid",
        Titel: "invalid",
        Vorname: "name",
        Name: "surname",
        Geburtsdatum: {
          field: "birthDate",
          callback: (value) => {
            return new Date(value).toLocaleDateString("tr") || value;
          },
        },
        Firma: "invalid",
        "Straße/Hausnummer": "address.street",
        Postleitzahl: "address.postalCode",
        Ort: "address.city",
        Bundesland: "invalid",
        Land: {
          field: "default",
          callback: () => "Deutschland",
        },
        "Land (Code)": {
          field: "default",
          callback: () => "DE",
        },
        Postfach: "invalid",
        "Postfach-Postleitzahl": "invalid",
        "Postfach-Ort": "invalid",
        "Postfach-Bundesland": "invalid",
        "Postfach-Land": "invalid",
        "Postfach-Land (Code)": "invalid",
        "Telefon 1": "invalid",
        "Telefon 2": "invalid",
        Fax: "invalid",
        Mobiltelefon: "phoneNo",
        "E-Mail": "email",
        "Weitere E-Mails": "invalid",
        Internet: "invalid",
        "Info 1": "invalid",
        "Info 2": "invalid",
        "Steuer Nr.": "invalid",
        "Umsatzsteuer-ID Nr.": "invalid",
        Registergericht: "invalid",
        Registernummer: "invalid",
        Kontoinhaber: "invalid",
        Bankname: "invalid",
        Kontonummer: "invalid",
        Bankleitzahl: "invalid",
        BIC: "invalid",
        IBAN: "iban",
        "Zusatz 1": "invalid",
        "Zusatz 2": "invalid",
        Notiz: "invalid",
        Kundengruppe: "invalid",
        "Zusätzliche Kundengruppen": "invalid",
        "Titel-Lieferanschrift": "invalid",
        "Anrede-Lieferanschrift": "invalid",
        "Persönlich/Vertraulich-Lieferanschrift": "invalid",
        "Vorname-Lieferanschrift": "invalid",
        "Name-Lieferanschrift": "invalid",
        "Zusatz 1-Lieferanschrift": "invalid",
        "Zusatz 2-Lieferanschrift": "invalid",
        "Firma-Lieferanschrift": "invalid",
        "Straße/Hausnummer-Lieferanschrift": "invalid",
        "Postleitzahl-Lieferanschrift": "invalid",
        "Ort-Lieferanschrift": "invalid",
        "Bundesland-Lieferanschrift": "invalid",
        "Land-Lieferanschrift": "invalid",
        "Land-Lieferanschrift (Code)": "invalid",
        Preisgruppe: "invalid",
        "Kunde seit": "invalid",
        "SEPA-Lastschriftverfahren": "invalid",
        "Eingangsdatum des Mandates": "invalid",
        Mandatsreferenz: "invalid",
        "Skonto (%)": "invalid",
        "Skonto (Tage)": "invalid",
        "Rabatt (%/Betrag)": "invalid",
        "Zahlungsziel (Tage)": "invalid",
        Umsatzsteueroption: "invalid",
        "Umsatzsteueroption ID": "invalid",
        "Ansprechpartner ID": "invalid",
        "Dokumentformat beim Versenden": "invalid",
        "Referenz des Käufers": "invalid",
        "Lieferantennr. beim Kunden": "invalid",
        Archiviert: "invalid",
        "Leere Spalte": "invalid",
      },
      statusList: [
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 4, text: "pendingConfirmation" },
        { value: 6, text: "paymentWaiting" },
        { value: 2, text: "completed" },
        { value: 5, text: "transmittedFa" },
        { value: 3, text: "canceled" },
      ],
      marriageStatus: [
        { value: 1, text: "single" },
        { value: 2, text: "married" },
        { value: 3, text: "divorced" },
        { value: 4, text: "widowed" },
      ],
      copied: false,
    };
  },
  computed: {
    ...mapGetters(["activeUser", "getOS"]),
  },
  methods: {
    // { value: 0, text: "waiting" },
    // { value: 1, text: "reviewing" },
    // { value: 4, text: "pendingConfirmation" },
    // { value: 6, text: "paymentWaiting" },
    // { value: 2, text: "completed" },
    // { value: 5, text: "transmittedFa" },
    // { value: 3, text: "canceled" },
    STATUS_CHANGE_DISABLED(status) {
      if (
        this.activeUser.role !== "Manager" &&
        [2, 5].includes(this.status) &&
        [0, 1, 4, 6].includes(status.value)
      )
        return true;
      else if (
        this.activeUser.role !== "Manager" &&
        this.status === 5 &&
        status.value !== 5
      )
        return true;
      else return false;
    },
    copyClickboard() {
      navigator.clipboard
        .writeText(
          `https://flinktax.com/#/pay/${this.user.id}/${this.$route.params.year}`
        )
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 4000);
        });
    },
    userStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : val === 6
        ? { class: "pink", text: "paymentWaiting" }
        : { class: "danger", text: "canceled" };
    },
    async UPDATE_STATUS(userId, taxYear) {
      const response = await putUserStatus(
        userId,
        taxYear,
        this.status,
        this.$i18n.locale
      );
      if (response.message === "OK") {
        this.user.status = this.status;
      } else this.status = this.user.status;
    },
    setMarriageStatus(status) {
      return (
        this.marriageStatus.find((m) => m.value === status) || {
          value: -1,
          text: "unknown",
        }
      );
    },
    setBirthday(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async getUser() {
      this.isLoadingUserInfo = true;
      const response = await getUserInfo(
        this.$route.params.id,
        this.$route.params.year
      );
      if (response.message === "OK") {
        this.user = response.data;
        this.status = response.data.status;
      } else this.user = {};
      this.isLoadingUserInfo = false;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss">
.account-settings {
  .custom-select-lg {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
</style>
